<template>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" >
    <v-icon v-if="snackbar.color == 'red'">mdi-alert-circle-outline</v-icon> 
    <v-icon v-if="snackbar.color == 'green'">mdi-check</v-icon> 
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    text: {
      type:String,
      required:true
    },
  },
  data() {
    return {
      snackbar: {
        show:this.show,
        color:this.color,
        text:this.text
      }
    }
  }
};
</script>
